<template>
  <div style="max-width: 1200px; margin: auto">
    <v-card class="main-card">
      <h2 v-if="locale === 'en'">Information</h2>
      <h2 v-else-if="locale === 'de'">Information</h2>
      <h2 v-else>Informacije</h2>
      <hr style="margin-top: 1px; margin-bottom: 20px">

      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2595.3329571932345!2d15.654251261916954!3d46.56126548593003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476f77074ca00eb9%3A0x2989102a6532a5ff!2sGoro%20trgovina%20in%20storitve%20d.o.o.!5e0!3m2!1sen!2ssi!4v1673380796542!5m2!1sen!2ssi" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

      <div>
        <br><br><br>
        <br><h5>Podatki o podjetju:</h5><br>
        <p style="margin-left: 2%">Naziv: <strong>Goro d.o.o.</strong></p>
        <p style="margin-left: 2%">Naslov: <strong>Partizanska cesta 23, Maribor, 2000 Maribor</strong></p>
        <p style="margin-left: 2%">Dav. št.:: <strong>15250628</strong></p>
        <p style="margin-left: 2%">Mat. št.: <strong>5735408000</strong></p>
        <br><br><br>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "info.vue",
  title: "ZAVESE - Informacije",
  data() {
    return {
      locale: "",
    };
  },
  computed: {

  },
  mounted(){
    if(localStorage.getItem("zavese-locale") === null){
      localStorage.setItem("zavese-locale", "si")
    }
    this.locale = localStorage.getItem("zavese-locale")
  },
  methods: {

  }
}
</script>

<style scoped>

  .main-card {
    padding: 30px;
    padding-bottom: 60px;
    margin: 15px;
    margin-top: 25px;
    background-color: #f3f3f3;
    max-width: 1800px;
  }

  @media only screen and (max-width: 950px){
    .main-card {
      padding: 5px;
    }
  }

</style>
